.footer{
    background-image: url(../../assets/lastball1.jpeg);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 35vh;
}


.footer-container{
    padding: 3rem;
}

.footer-content{
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    font-weight: 500;
    gap: 5rem;
}

.footer-text-1{
    display: flex;
    flex-direction: column;
    width: 35%;
}

.footer-text-1>span:nth-child(1){
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 2rem;
    font-size: 20px;
    color: orangered;
}

.location-icon{
    font-size: 17px;
    padding-top: .7rem;
}

.footer-text-1>span:nth-child(3){
    padding-bottom: 1rem;
    font-size: 17px;
}

.footer-text-1>span:nth-child(5){
    font-size: 17px;
}

.footer-text-2{
    width: 21%;
}

.footer-text-2>span{
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    color: orangered;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 1rem;
}

.sponsor-img{
    margin-top: 2.5rem;
    /* display: flex;
    align-items: center; */
}

.footer-text-2>.sponsor-img>img{
    width: 25%;
}

/* .footer-links{
    width: 33%;
    list-style: none;
    font-size: 17px;
    gap: .5rem;
    flex-direction: column;
    display: flex;
    cursor: pointer;
}

.footer-links>li:hover{ 
    color: orangered;
    cursor: pointer;
    font-size: 20px;
} */

.footer-text-3{
    width: 25%;
}

.footer-text-3>span{
    display: flex;
    justify-content: center;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    color: orangered;
    padding-bottom: 5rem;
}

.footer-icons{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.media-icon{
    font-size: 30px;
}

.media-icon:hover{ 
    color: orangered;
    cursor: pointer;
    font-size: 35px;
}

.copyright{
    background-color: white;
}


/* TABLETVIEW 1025px */


@media screen and (max-width: 1025px){
    .footer-text-1>span:nth-child(1){
        font-size: 25px;
    }

    .footer-text-1>span:nth-child(3){
        font-size: 20px;
    }

    .footer-text-1>span:nth-child(5){
        font-size: 20px;
    }

    .footer-text-2>span{
        font-size: 25px;
    }

    .footer-text-2>.sponsor-img>img{
        width: 32%;
    }

    .media-icon{
        font-size: 38px;
    }

    .footer-text-3>span{
        font-size: 25px;
    }
}



/* TABLETVIEW 1024  */

    @media screen and (max-width: 1024px){
        .location-icon{
            font-size: 17px;
        }

        .footer-text-1>span:nth-child(1){
            font-size: 25px;
        }

        .footer-text-1>span:nth-child(3){
            font-size: 20px;
        }

        .footer-text-1>span:nth-child(5){
            font-size: 20px;
        }

        .footer-text-2>span{
            font-size: 25px;
        }

        .footer-text-2>.sponsor-img>img{
            width: 32%;
        }

        .media-icon{
            font-size: 38px;
        }

        .footer-text-3>span{
            font-size: 25px;
        }


    }



    /* SCREEN 768 */

    @media screen and (max-width:768px){

    .footer-container{
        padding: 1.5rem;
    }

     .footer-content{
        gap: 2.5rem;
    } 

    .location-icon{
        margin-top: -1rem;
        font-size: 15px;
    }

    .footer-text-1>span:nth-child(1){
        font-size: 25px;
    }

    .footer-text-1>span:nth-child(3) {
        font-size: 20px;
        padding-bottom: 2rem;
    }

    .footer-text-1>span:nth-child(5){
        font-size: 20px;
    }

    .footer-text-2>span:nth-child(1), .footer-text-3>span:nth-child(1){
        font-size: 25px;
    } 

    .footer-text-2>.sponsor-img{
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
            
        
    }

    .footer-text-2>.sponsor-img>img{
        width: 35%; 
    }

    .footer-icons{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: -2.5rem; 
        margin-left: 1.5rem;
       
    }
    
    .media-icon{
        font-size: 28px;
        
    }

    .media-icon:hover{ 
        color: orangered;
        font-size: 25px;
    } 

}




    /* SCREEN 480 */

        @media screen and (max-width:480px){
            .footer-text-1>span:nth-child(1) {
                font-size: 23px;
            }

            .footer-text-1>span:nth-child(3) {
                font-size: 17px;            
            }

            .footer-text-1>span:nth-child(5) {
                font-size: 17px;
            }

            .footer-text-2>span:nth-child(1), 
            .footer-text-3>span:nth-child(1) {
                font-size: 23px;
            }

            .footer-text-2>.sponsor-img>img {
                width: 50%;
            }

            .media-icon{
                font-size: 25px;
            }
        }

        



    /* SCREEN 320 */

    @media screen and (max-width:320px){
        .location-icon{
            margin-top: -1rem;
        }

        .footer-text-1>span:nth-child(1){
            font-size: 15px;
        }

        .footer-text-2>span:nth-child(1){
            font-size: 15px;
        }

        .footer-text-3>span:nth-child(1){
            font-size: 15px;
        }

        .footer-text-2>.sponsor-img>img{
            width: 75%;
        }

        .footer-text-1>span:nth-child(3){
            font-size: 13px;
        }
    
        .footer-text-1>span:nth-child(5){
            font-size: 13px;
        }
    }


